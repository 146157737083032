import { External } from "../components/icons";
import Layout from "../layouts";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const AppsPage = () => (
  <Layout>
    <p className="leads-the-things emboldernised">We've been building stuff!</p>

    <div className="apps">
      <div className="app-section">
        <a
          href="https://startkit.ai?ref=squarecat-apps"
          target="_blank"          
        >
          <h3>
            StartKit.AI
            <External />
          </h3>
        </a>
        <p className="app-text">
          All you need to build a complete AI tool. Build your next AI startup 100x faster with our boilerplate code.
        </p>
        <div className="app__image app__image--bordered">
          <StaticImage
            src="../assets/images/apps/startkit-ai.png"
            alt="Leave Me Alone website"
          />
        </div>
      </div>
      <div className="app-section">
        <a
          href="https://leavemealone.com?ref=squarecat-apps"
          target="_blank"          
        >
          <h3>
            Leave Me Alone
            <External />
          </h3>
        </a>
        <p className="app-text">
          Easily unsubscribe from unwanted emails. See all of your subscription
          emails in one place and unsubscribe from them in a single click.
        </p>
        <div className="app__image app__image--bordered">
          <StaticImage
            src="../assets/images/apps/leavemealone.png"
            alt="Leave Me Alone website"
          />
        </div>
      </div>

      <div className="app-section">
        <a
          href="https://subscriptionscore.com?ref=squarecat-apps"
          target="_blank"          
        >
          <h3>
            Subscription Score
            <External />
          </h3>
        </a>
        <p className="app-text">
          Never regret subscribing to a mailing list again. See the details of
          over half a million mailing lists, before you give them your email
          address.
        </p>
        <div className="app__image app__image--bordered">
          <StaticImage
            src="../assets/images/apps/subscription-score.png"
            alt="Subscription Score"
          />
        </div>
      </div>

      <div className="app-section">
        
        <h3>
          UptimeBar (discontinued)      
        </h3>
        
        <p className="app-text">
          A simple OSX menu bar app that notifies you if any of your websites
          are down. Never miss another minute of downtime!
        </p>
        <div className="app__image">
          <StaticImage
            src="../assets/images/apps/uptimebar.png"
            alt="Uptime Bar App"
          />
        </div>
      </div>

      <div className="app-section">
        <a
          href="https://releasepage.co?ref=squarecat-apps"
          target="_blank"
          rel="noreferrer"
        >
          <h3>
            ReleasePage
            <External />
          </h3>
        </a>
        <p className="app-text">
          A public release page for your web-app. Powered by GitHub, always
          up-to-date with your latest releases.
        </p>
        <div className="app__image">
          <StaticImage
            src="../assets/images/apps/releasepage.png"
            alt="ReleasePage Website"
          />
        </div>
      </div>

      <div className="app-section">
        <a
          href="https://makerads.xyz/?ref=squarecat-apps"
          target="_blank"
          rel="noreferrer"
        >
          <h3>
            MakerAds (sold)
            <External />
          </h3>
        </a>
        <p className="app-text">Unobtrusive adverts for makers.</p>
        <div className="app__image app__image--bordered">
          <StaticImage
            src="../assets/images/apps/maker-ads.png"
            alt="MakerAds Website"
          />
        </div>
      </div>

      <div className="app-section">
        <a
          href="https://twittersearchfixer.squarecat.io/?ref=squarecat-apps"
          target="_blank"
          rel="noreferrer"
        >
          <h3>
            Twitter Search Fixer
            <External />
          </h3>
        </a>
        <p className="app-text">
          Fix Twitter search by not accidentally clicking #hashtags.
        </p>
        <div className="app__image app__image--bordered">
          <StaticImage
            src="../assets/images/apps/twitter-search-fixer.png"
            alt="Twitter Search Fixer"
          />
        </div>
      </div>
    </div>
  </Layout>
);

export default AppsPage;
