import React from 'react';

export const Instagram = () => (
  <svg
    className="icon-social"
    fill="currentColor"
    width="20"
    height="20"
    viewBox="0 0 24 24"
  >
    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
  </svg>
);

export const Facebook = () => (
  <svg
    className="icon-social"
    fill="currentColor"
    width="20"
    height="20"
    viewBox="0 0 24 24"
  >
    <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
  </svg>
);

export const FacebookRound = () => (
  <svg
    className="icon-social"
    role="img"
    viewBox="0 0 24 24"
    width="20"
    height="20"
  >
    <path
      fill="currentColor"
      d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z"
    />
  </svg>
);

export const Twitter = () => (
  <svg className="icon-social" viewBox="0 0 64 64" width="20" height="20">
    <path
      strokeWidth="0"
      fill="currentColor"
      d="M60 16 L54 17 L58 12 L51 14 C42 4 28 15 32 24 C16 24 8 12 8 12 C8 12 2 21 12 28 L6 26 C6 32 10 36 17 38 L10 38 C14 46 21 46 21 46 C21 46 15 51 4 51 C37 67 57 37 54 21 Z"
    />
  </svg>
);

export const Telegram = () => (
  <svg
    className="icon-social telegram"
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <title>icon_telegram</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="footer"
        transform="translate(-270.000000, -48.000000)"
        fillRule="nonzero"
      >
        <g id="Group-4">
          <g id="icon_telegram" transform="translate(270.000000, 48.000000)">
            <path
              d="M0.07125,12.0305068 C0.07125,5.42554054 5.42564189,0.0711486486 12.0307095,0.0711486486 L12.0307095,0.0711486486 C18.635777,0.0711486486 23.9901689,5.42554054 23.9901689,12.0305068 L23.9901689,12.0305068 C23.9901689,18.6355743 18.635777,23.9900676 12.0307095,23.9900676 L12.0307095,23.9900676 C5.42564189,23.9900676 0.07125,18.6355743 0.07125,12.0305068"
              id="path30"
              fill="none"
              transform="translate(12.030709, 12.030608) scale(-1, 1) rotate(-180.000000) translate(-12.030709, -12.030608) "
            />
            <path
              d="M10.053914,13.9154989 L10.0479932,13.9119932 L9.77556081,16.8067905 C9.77556081,16.8067905 9.66154054,17.6937162 10.5483649,16.8067905 C11.1731146,16.1820408 11.7790018,15.6579411 12.082545,15.4027376 C13.0859276,16.0955593 14.166103,16.8621019 14.6319932,17.2628818 C14.8661149,17.4642669 15.0619257,17.4970034 15.223277,17.4909223 C15.6656757,17.474402 15.7891216,16.9884223 15.7891216,16.9884223 C15.7891216,16.9884223 17.8525338,8.68379392 17.9215541,7.57105743 C17.9282432,7.46210473 17.9374662,7.3925777 17.9383784,7.31767905 C17.9396959,7.21369257 17.9299662,7.10919932 17.9130405,7.06004392 C17.8666216,6.92494257 17.8114865,6.88794932 17.7251351,6.85521284 C17.5362162,6.78365878 17.2164527,6.89129392 17.2164527,6.89129392 C17.2164527,6.89129392 5.89327703,10.9612601 5.2464527,11.4119696 C5.10709459,11.5091655 5.06057432,11.5654155 5.03746622,11.631598 C4.92557432,11.9525777 5.27391892,12.0939628 5.27391892,12.0939628 L8.19233108,13.0449426 C8.19233108,13.0449426 8.30118243,13.0607534 8.34,13.0362264 C9.0039527,12.6167331 15.0197635,8.81798311 15.3667905,8.69028041 C15.4216216,8.67396284 15.4620608,8.69261149 15.4513176,8.73041554 C15.3122635,9.21771284 10.0873986,13.8621385 10.0873986,13.8621385 C10.0873986,13.8621385 10.0664651,13.8860328 10.053914,13.9154989 Z"
              id="Combined-Shape"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const External = () => (
  <svg
    className="icon-external"
    viewBox="0 0 32 32"
    width="20"
    height="20"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
  >
    <path d="M14 9 L3 9 3 29 23 29 23 18 M18 4 L28 4 28 14 M28 4 L14 18" />
  </svg>
);

export const Close = () => (
  <svg
    viewBox="0 0 32 32"
    width="22"
    height="22"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="4"
  >
    <path d="M2 30 L30 2 M30 30 L2 2" />
  </svg>
);

export const Mail = () => (
  <svg
    className="icon-social"
    viewBox="0 0 32 32"
    width="20"
    height="20"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
  >
    <path d="M2 26 L30 26 30 6 2 6 Z M2 6 L16 16 30 6" />
  </svg>
);
